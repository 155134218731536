
import {
    Vue,
    Component,
    PropSync,
    ModelSync,
    Prop,
    Ref
} from "vue-property-decorator";
import { mapMutations, mapGetters } from "vuex";

import {
    FilterFormDefinitive,
    FormActions,
    ResponseDataStringArray as rdsa
} from "@/interfaces";
import * as types from "@/store/types";

@Component({
    components: {
        FilterFormPanelBase: () =>
            import("@/components/layout/FilterFormPanelBase.vue"),
        CalendarRangeInput: () =>
            import("@/components/layout/CalendarRangeInputPre.vue"),
        CalendarInput: () => import("@/components/layout/CalendarInput.vue")
    },
    methods: {
        ...mapMutations({
            setFilterLoading: types.MUTATE_APP_FILTER_LOADING,
            setInfoMessage: types.MUTATE_APP_INFO_MESSAGE,
            setEmpresa: types.MUTATE_EMPRESA
        })
    },
    computed: {
        ...mapGetters({
            userIdentifier: types.USER_IDENTIFIER
        })
    }
})
export default class FilterForm extends Vue {
    @ModelSync("filterForm", "change", { type: Object })
    readonly formValues!: FilterFormDefinitive;

    @Ref("form") readonly formActions!: FormActions;

    @PropSync("filterFormPanelShow", { type: Boolean || null })
    syncFilterFormPanelShow!: null;

    @Prop({ type: Boolean, default: true, required: false })
    readonly displayDates!: boolean;

    setFilterLoading!: (state: boolean) => void;
    setInfoMessage!: (state: { shown: boolean; text: string | null }) => void;
    setEmpresa!: (state: number) => void;

    path = this.$route.path;
    userIdentifier?: number;
    companies: string[] = [];
    boxes: string[] = [];
    clients: string[] = [];
    installations: string[] = [];
    analyzers: string[] = [];
    listElements: string[] = [];
    paramElments: any[] = [];
    elements: string[] = [];
    childElements: string[] = [];
    selected: string[] = [];
    selectedChild: string[] = [];
    listInversors: string[] = [];
    paramInversors: any[] = [];
    inversors: string[] = [];
    Iselected: string[] = [];
    periodType: string[] = [];
    modos = ["Contrato", "Precio de Mercado"];
    advancedOptions: boolean | null = false;
    start1 = "";
    start2 = "";
    // if !comparative -> dates, else start1 and start2
    first = false;
    isClient = true;
    rules = {
        required: value => !!value || "Requerido",
        counter: value => value.length <= 3 || "Maximo 3 dígitos",
        days: value => !!Number(value) || "No es un número"
    };
    formRules = {
        window: [
            val =>
                /[0-9][d]{1}$|[0-9][h]{1}$|[0-9][m]{1}$|[0-9][s]{1}$/.test(
                    val
                ) || val == null
        ]
    };

    loading = false;

    created() {
        this.$root.$on("InstalacionOnMap", () => {
            this.installationFromMap();
        });
    }

    mounted() {
        this.windowUpdate();
        this.formValues.elements = [];
        this.formValues.window = "1dia";
        this.formValues.installation = "";
        this.formValues.comparative = false;
        this.formValues.days = "30";
        this.formValues.noHijos = false;
        this.fetchUser();
    }

    /**
     * Methods
     */

    installationFromMap() {
        try {
            const aux = localStorage.getItem("installationFromMaps");
            if (aux != "" && aux != null && aux != undefined) {
                const install = JSON.parse(aux);
                if (install != "" && install != null && install != undefined) {
                    this.formValues.company = install.empresa;
                    this.formValues.client = install.cliente;
                    this.setFilter();
                } else {
                    throw Error;
                }
            } else {
                throw Error;
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError("Instalación no encontrada");
            }
        }
    }

    setFilter() {
        this.$emit("on-filter-updated");
    }

    datesUpdates() {
        if (this.start1 && this.start2) {
            this.formValues.dates = [this.start1, this.start2];
            this.windowUpdate();
        }
    }

    async fetchUser() {
        if (Vue.prototype.$user.get().role == "client") {
            this.setFilterLoading(true);
            try {
                const userClientResponse = await this.$api.SQLuserClient<rdsa>(
                    this.userIdentifier
                );
                if (userClientResponse.ok) {
                    this.setEmpresa(
                        Number(userClientResponse.data[0].split(" - ")[0])
                    );
                    this.formValues.company = userClientResponse.data[0];
                    this.formValues.client = userClientResponse.data[1];
                    this.fetchInstallation();
                }
            } catch (error) {
                if (error instanceof Error) {
                    this.showError(error.message);
                }
            } finally {
                this.setFilterLoading(false);
            }
        } else if (Vue.prototype.$user.get().role == "admin") {
            this.setFilterLoading(true);
            this.isClient = false;
            this.fetchCompany();
            this.setFilterLoading(false);
        } else {
            console.log("Role undefined");
        }
    }

    async fetchCompany() {
        this.setFilterLoading(true);
        try {
            const companyResponse = await this.$api.SQLcompany<rdsa>();
            if (companyResponse.ok) {
                this.companies = companyResponse.data;
                this.formValues.company = this.companies[0];
                this.fetchClient();
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        } finally {
            this.setFilterLoading(false);
        }
    }

    async fetchClient() {
        this.setFilterLoading(true);
        try {
            const clientResponse = await this.$api.SQLclient<rdsa>(
                this.formValues.company.split(" - ")[0]
            );
            if (clientResponse.ok) {
                this.clients = clientResponse.data;
                this.formValues.client = this.clients[0];
                this.fetchInstallation();
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        } finally {
            this.setFilterLoading(false);
        }
    }

    async fetchInstallation() {
        this.setFilterLoading(true);
        try {
            const installResponse = await this.$api.SQLinstallation<rdsa>(
                this.formValues.client.split(" - ")[0]
            );
            if (installResponse.ok) {
                this.installations = installResponse.data;
                this.formValues.installation = this.installations[0];
                this.fetchAll();
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        } finally {
            this.setFilterLoading(false);
        }
    }

    async fetchAll() {
        this.setFilterLoading(true);
        try {
            const allResponse =
                this.path == "/energy" || this.path == "/v2/energy"
                    ? await this.$api.SQLall<rdsa>(
                          this.formValues.installation.split(" - ")[0],
                          true
                      )
                    : await this.$api.SQLall<rdsa>(
                          this.formValues.installation.split(" - ")[0]
                      );
            if (allResponse.ok) {
                this.listElements = allResponse.data["element"];
                this.listInversors = allResponse.data["inversor"];
                this.toJSON();
            }
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
    }

    toJSON() {
        this.setFilterLoading(true);
        try {
            this.elements = [];
            this.childElements = [];
            for (const elemento of this.listElements) {
                if (Number(elemento[5]) == 0) {
                    this.elements.push(elemento[3]);
                }
                this.paramElments[elemento[3]] = {
                    install: elemento[0],
                    box: elemento[1],
                    analyzer: elemento[2],
                    Tdir: elemento[4],
                    Jerar: elemento[5],
                    NpaCons: elemento[6],
                    nPadre: elemento[7]
                };
            }
            this.selected = this.elements;
            this.setElementChild();
            this.setElements();
            this.inversors = [];
            for (const elemento of this.listInversors) {
                this.inversors.push(elemento[3]);
                this.paramInversors[elemento[3]] = {
                    install: elemento[0],
                    box: elemento[1],
                    analyzer: elemento[2],
                    Tdir: elemento[4]
                };
            }
            this.Iselected = this.inversors;
            this.setInversors();
        } catch (error) {
            if (error instanceof Error) {
                this.showError(error.message);
            }
        }
    }

    setElementChild() {
        this.childElements = [];
        this.selectedChild = [];
        for (const elementoPadre of this.selected) {
            for (const elemento of this.listElements) {
                if (Number(elemento[5]) > 0) {
                    if (elemento[6] == elementoPadre.split(" - ")[0]) {
                        this.childElements.push(elemento[3]);
                    }
                }
            }
        }
        this.selectedChild = this.childElements;
        this.setElementGrandchild();
    }

    setElementGrandchild() {
        for (const elementoHijo of this.selectedChild) {
            for (const elemento of this.listElements) {
                if (Number(elemento[5]) > 1) {
                    if (
                        elemento[6] ==
                        elementoHijo.split(" - ")[0].split("/")[1]
                    ) {
                        this.childElements.push(elemento[3]);
                    }
                }
            }
        }
        this.selectedChild = this.childElements;
    }

    setElements() {
        this.setFilterLoading(true);
        this.formValues.elements = [];

        if (this.path != "/energy" && this.path != "/v2/energy") {
            for (const elemento of this.selected) {
                this.formValues.elements.push({
                    install: this.paramElments[elemento]["install"],
                    box: this.paramElments[elemento]["box"],
                    analyzer: this.paramElments[elemento]["analyzer"],
                    Telement: elemento,
                    Tdir: this.paramElments[elemento]["Tdir"]
                });
            }
        } else {
            for (const elemento of this.selected) {
                this.formValues.elements.push({
                    install: this.paramElments[elemento]["install"],
                    box: this.paramElments[elemento]["box"],
                    analyzer: this.paramElments[elemento]["analyzer"],
                    Telement: elemento,
                    Tdir: this.paramElments[elemento]["Tdir"],
                    Jerar: this.paramElments[elemento]["Jerar"],
                    NpaCons: this.paramElments[elemento]["NpaCons"],
                    nPadre: this.paramElments[elemento]["nPadre"]
                });
                if (!this.formValues.noHijos) {
                    for (const child of this.selectedChild) {
                        if (child.split("/")[0] == elemento.split(" - ")[0]) {
                            this.formValues.elements.push({
                                install: this.paramElments[child]["install"],
                                box: this.paramElments[child]["box"],
                                analyzer: this.paramElments[child]["analyzer"],
                                Telement: child,
                                Tdir: this.paramElments[child]["Tdir"],
                                Jerar: this.paramElments[child]["Jerar"],
                                NpaCons: this.paramElments[child]["NpaCons"],
                                nPadre: this.paramElments[child]["nPadre"]
                            });
                            for (const grandChild of this.selectedChild) {
                                if (
                                    child.split("/")[1].split(" - ")[0] ==
                                    grandChild.split("/")[0]
                                ) {
                                    this.formValues.elements.push({
                                        install: this.paramElments[grandChild][
                                            "install"
                                        ],
                                        box: this.paramElments[grandChild][
                                            "box"
                                        ],
                                        analyzer: this.paramElments[grandChild][
                                            "analyzer"
                                        ],
                                        Telement: grandChild,
                                        Tdir: this.paramElments[grandChild][
                                            "Tdir"
                                        ],
                                        Jerar: this.paramElments[grandChild][
                                            "Jerar"
                                        ],
                                        NpaCons: this.paramElments[grandChild][
                                            "NpaCons"
                                        ],
                                        nPadre: this.paramElments[grandChild][
                                            "nPadre"
                                        ]
                                    });
                                }
                            }
                        }
                    }
                }
            }
        }
        this.setFilterLoading(false);
    }

    setInversors() {
        this.setFilterLoading(true);
        this.formValues.inversors = [];
        for (const elemento of this.Iselected) {
            if (this.formValues.inversors) {
                this.formValues.inversors.push({
                    install: this.paramInversors[elemento]["install"],
                    box: this.paramInversors[elemento]["box"],
                    analyzer: this.paramInversors[elemento]["analyzer"],
                    Telement: elemento,
                    Tdir: this.paramInversors[elemento]["Tdir"]
                });
            }
        }
        this.setFilterLoading(false);
    }

    showError(error: string) {
        this.setInfoMessage({ shown: true, text: error });
    }

    windowUpdate() {
        if (this.formValues.comparative) {
            const c = Number(this.formValues.days);
            if (c > 2 && c < 7) {
                this.periodType = ["1hora", "1dia"];
                this.formValues.window = "1dia";
            } else if (c >= 7 && c < 96) {
                this.periodType = ["1dia", "1mes"];
                this.formValues.window = "1dia";
            } else if (c >= 96 && c < 365) {
                this.periodType = ["1dia", "1mes", "1año"];
                this.formValues.window = "1mes";
            } else if (c >= 365) {
                this.periodType = ["1mes", "1año"];
                this.formValues.window = "1año";
            } else {
                this.periodType = ["15minutos", "1hora"];
                this.formValues.window = "1hora";
            }
        } else if (
            this.formValues.dates.length > 1 &&
            this.path != "/resumen" &&
            this.path != "/v2/resumen"
        ) {
            const a = new Date(this.formValues.dates[0]);
            const b = new Date(this.formValues.dates[1]);
            const c = Math.abs(Number(b) - Number(a)) / (3600000 * 24);
            if (c > 2 && c < 7) {
                this.periodType = ["1hora", "1dia"];
                this.formValues.window = "1hora";
            } else if (c >= 7 && c < 96) {
                this.periodType = ["1hora", "1dia", "1mes"];
                this.formValues.window = "1dia";
            } else if (c >= 96 && c < 365) {
                this.periodType = ["1dia", "1mes", "1año"];
                this.formValues.window = "1mes";
            } else if (c >= 365) {
                this.periodType = ["1hora", "1mes", "1año"];
                this.formValues.window = "1año";
            } else {
                this.periodType = ["15minutos", "1hora"];
                this.formValues.window = "15minutos";
            }
        } else if (
            !this.formValues.comparative &&
            (this.path == "/resumen" || this.path == "/v2/resumen")
        ) {
            const c = Number(this.formValues.days);
            if (c > 2 && c < 7) {
                this.periodType = ["1hora", "1dia"];
                this.formValues.window = "1hora";
            } else if (c >= 7 && c < 96) {
                this.periodType = ["1dia", "1mes"];
                this.formValues.window = "1dia";
            } else if (c >= 96 && c < 365) {
                this.periodType = ["1dia", "1mes", "1año"];
                this.formValues.window = "1mes";
            } else if (c >= 365) {
                this.periodType = ["1mes", "1año"];
                this.formValues.window = "1año";
            } else {
                this.periodType = ["15minutos", "1hora"];
                this.formValues.window = "15minutos";
            }
            this.formValues.dates[1] = new Date().toISOString().split("T")[0];
            this.formValues.dates[0] = new Date(
                new Date(this.formValues.dates[1]).setDate(
                    new Date(this.formValues.dates[1]).getDate() -
                        Number(this.formValues.days) +
                        1
                )
            )
                .toISOString()
                .split("T")[0];
        } else {
            this.periodType = ["15minutos", "1hora"];
            this.formValues.window = "1hora";
        }
    }

    resetForm0() {
        this.formValues.client = "";
        this.formValues.installation = "";
        this.formValues.elements = [];
        this.formValues.inversors = [];
        this.elements = [];
        this.childElements = [];
        this.selectedChild = [];
        this.inversors = [];
        this.selected = [];
        this.Iselected = [];
        this.$emit("no-csv");
    }

    resetForm1() {
        this.formValues.installation = "";
        this.formValues.elements = [];
        this.formValues.inversors = [];
        this.elements = [];
        this.childElements = [];
        this.selectedChild = [];
        this.inversors = [];
        this.selected = [];
        this.Iselected = [];
        this.$emit("no-csv");
    }

    resetForm2() {
        this.formValues.elements = [];
        this.formValues.inversors = [];
        this.elements = [];
        this.childElements = [];
        this.selectedChild = [];
        this.inversors = [];
        this.selected = [];
        this.Iselected = [];
        this.$emit("no-csv");
    }

    resetForm3() {
        this.$emit("no-csv");
    }
}
